a {
    color: inherit;
    text-decoration: none;
}
.question-image-detail{
height: 100%;
    width: 100%;
    padding: 24px;
    object-fit: contain;
}

.shepherd-theme-custom{
    font-family: 'manrope';
    font-weight: 700;

    
}
.tour-box{
    z-index: 2;

}